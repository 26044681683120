<template>
  <div>
    <div class="h-row">
      <div>
        <div>
          <label :class="goalInfo.hasOwnProperty('label') && goalInfo.label && 'h-row__label'"
            >{{ goalName }}:

            <img
              v-if="hasTooltipMessage(shortCode)"
              src="@/assets/svgs/goal-planner/ic_info.svg"
              alt="arrow"
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              :title="getTooltipMessage"
            />
          </label>
        </div>
        <span v-if="goalInfo.hasOwnProperty('label')">{{ goalInfo.label }}</span>
      </div>

      <label v-if="isGoalLocked" class="form-control form-control-sm locked-label">{{ formatAmount(planGoal) }}</label>
      <div v-else class="row__block">
        <b-input-group prepend="$" :class="{ opaque: !isDollarValue }">
          <GoalBaseInput
            :maximumDigitsAllowed="allowedMaximumNoOfDigits"
            v-model.number="goalModelValue"
            class="form-control form-control-sm"
          />
        </b-input-group>
        <div v-if="$v.planningGoal.$invalid" class="error">
          Enter a valid value
        </div>
      </div>
      <button
        v-if="hasPreviousFYGoals"
        :disabled="isGoalLocked"
        @click="transferGoal"
        v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
        :title="!isGoalLocked && 'Click the blue button to use current year value for the upcoming year.'"
        class="h-row__btn"
      >
        <img src="@/assets/svgs/goal-planner/ic_arrow.svg" alt="arrow" />
      </button>
      <div v-if="hasPreviousFYGoals" class="h-row__data">
        <ul>
          <li>
            {{ lastFYGoals.actualFY2Goal.year }}

            Actual:
            {{ actualFY2Goal }}
          </li>
          <li>{{ lastFYGoals.fY1Goal.year }} Goal: {{ fiscalYear1Goal }}</li>
        </ul>
      </div>
    </div>
    <div v-if="goalInfo.hasOwnProperty('info') && goalInfo.info">
      <p class="h-content">
        <span v-html="goalInfo.info"></span>
      </p>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { numeric, required } from 'vuelidate/lib/validators'
import GoalBaseInput from './GoalBaseInput.vue'
import { ToolTipsMessage } from '@/services/utils/TooltipsMessages.js'
import { GoalsWithTooltips } from '@/constants/GoalsWithTooltips.js'
import CampGoalService from '@/services/goals/CampGoal.js'

export default {
  model: {
    prop: 'planGoal',
    event: 'input'
  },
  props: {
    goalName: {
      type: String,
      required: true
    },
    planGoal: {
      type: [Number, String],
      required: true
    },
    lastFYGoals: {
      type: Object,
      required: true
    },
    isGoalLocked: {
      type: Boolean,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    },
    goalInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      planningGoal: this.planGoal
    }
  },
  validations: {
    planningGoal: { numeric, required }
  },
  computed: {
    goalModelValue: {
      get() {
        return this.planGoal
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isDollarValue() {
      return CampGoalService.isGoalDollar(this.shortCode)
    },
    getTooltipMessage() {
      return ToolTipsMessage(this.shortCode)
    },
    allowedMaximumNoOfDigits() {
      return CampGoalService.getCampMaximumInputBound(this.shortCode)
    },
    fiscalYear1Goal() {
      return this.formatAmount(this.lastFYGoals.fY1Goal.goal)
    },
    actualFY2Goal() {
      return this.formatAmount(this.lastFYGoals.actualFY2Goal.goal)
    },
    hasPreviousFYGoals() {
      return Object.keys(this.lastFYGoals).length > 0
    }
  },
  methods: {
    hasTooltipMessage(shortCode) {
      return GoalsWithTooltips.includes(shortCode)
    },
    transferGoal() {
      if (!this.isGoalLocked) {
        this.goalModelValue =
          typeof this.lastFYGoals.fY1Goal.goal === 'number' ? parseInt(this.lastFYGoals.fY1Goal.goal) : 0
      }
    },
    formatAmount(value) {
      const isGoalDollarValue = CampGoalService.isGoalDollar(this.shortCode)
      return isGoalDollarValue ? CampGoalService.prependDollarSign(FormatNumber(value)) : FormatNumber(value)
    }
  },
  watch: {
    goalModelValue: {
      handler(newVal) {
        this.$emit('goal-input-change', newVal, this.shortCode)
        this.planningGoal = newVal
      }
    },
    planningGoal() {
      if (this.$v.planningGoal.$invalid) {
        this.$emit('input-validate-status', 'ERROR', this.shortCode)
      } else {
        this.$emit('input-validate-status', 'SUCCESS', this.shortCode)
      }
    }
  },
  components: { GoalBaseInput }
}
</script>
<style lang="scss">
.opaque {
  .input-group-prepend {
    opacity: 0;
  }
}
.error {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-left: 0px;
  margin-top: 5px;
}
.h-row {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
  .h-row__label {
    padding-top: 0;
    span {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .locked-label {
    padding: 11px 10px 10px 10px;
    margin-right: 14px;
    text-align: right;
    background: #efefef;
    color: #777777;
    width: 300px;
    min-height: 50px;
    cursor: not-allowed;
    display: block;
  }
  label {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    min-width: 300px;
    padding: 15px 10px 0 0;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    img {
      padding-left: 5px;
    }
  }
  .row__block {
    margin-right: 20px;
    .input-group {
      margin: 0 15px;
      width: auto;
      border-radius: 0;
      input[type='text'] {
        min-height: 50px;
        max-width: 329px;
        text-align: right;
        border-color: #777777;
      }
      .input-group-text {
        background: #ececec;
        color: #003946;
        border-radius: 0;
      }
    }
  }

  .h-row__btn {
    min-height: 40px;
    min-width: 67px;
    background: #01a9cf;
    border: none;
    margin-top: 5px;
    border-radius: 3px;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
.h-row__data {
  margin-left: 40px;
  display: flex;
  ul {
    li {
      list-style: none;
    }
  }
  img {
    margin-right: 5px;
    cursor: pointer;
  }
}

@media (min-width: 320px) and (max-width: 900px) {
  .h-row {
    flex-direction: column;
    align-items: flex-start;
    label {
      margin-bottom: 4px;
    }
    input[type='text'] {
      margin-bottom: 6px;
    }
    .h-row__data {
      margin: 10px 0 0;
    }
  }
}
</style>
