<template lang="">
  <div class="card card--mt card--pb-0">
    <div class="main-heading">
      GOAL PLANNING {{ correctGoalName }}
      <slot name="progress-badge"></slot>
    </div>
    <div class="g-panel">
      <div class="g-panel__lft">
        <p>The heart of man plans his way, but the LORD establishes his steps. Proverbs 16:9</p>
        <ul>
          <li>
            {{ officerToolbarSelected.camp_number }} -
            {{ officerToolbarSelected.camp_name.toUpperCase() }}
          </li>
          <slot name="goal-lock-warn-info"></slot>
          <li>Selected Fiscal Year: {{ selectedFiscalYear }}</li>
        </ul>
      </div>
    </div>
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    goalName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      getDashboardData: 'campGoalPlanner/getDashboardData',
      selectedFiscalYear: 'campGoalPlanner/selectedFiscalYear'
    }),
    correctGoalName() {
      return this.goalName.toUpperCase() === 'DASHBOARD' ? 'DASHBOARD' : '- ' + this.goalName.toUpperCase()
    }
  }
}
</script>
<style lang="scss">
.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}

.badge {
  position: relative;
  padding: 0.35rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
}

.badge--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.g-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  .g-panel__lft {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

    p {
      font-style: italic;
      color: #747474;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 10px;
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
        color: #000;
        font-weight: 700;
        list-style: none;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .lft__width {
      font-size: 20px;
      font-style: normal;
      max-width: 670px;
      line-height: 28px;
    }
  }

  .g-panel__rgt {
    display: flex;
    align-items: flex-start;

    .btn {
      padding: 11px 13px;
      font-size: 15px;
      line-height: 22px;
      text-transform: capitalize;

      &:first-child {
        margin-right: 12px;
      }

      img {
        margin-right: 3px;
      }
    }
  }
}
</style>
