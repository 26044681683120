<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template lang="">
  <div>
    <div class="heading-bordered">
      {{ isFaithFundPage ? 'FAITH FUND' : 'Goals' }}
      <span>{{ fY1Year }} Goal: {{ fY1Goal }}</span>
    </div>
    <goal-item
      class="faith-fund-label"
      v-for="(item, i) in goalsFormData"
      v-model="item.planGoal"
      v-if="isFaithFundPage && isFaithFundGoal(item.shortCode)"
      :goalName="item.goalName"
      :planGoal="item.planGoal"
      :lastFYGoals="item.lastFYGoals"
      :isGoalLocked="areGoalsLocked"
      :shortCode="item.shortCode"
      :goalInfo="item.goalInfo"
      @input-validate-status="inputValidateStatusHandler"
      @goal-input-change="goalInputChangeHandler"
      :key="i"
    >
    </goal-item>
    <goal-item
      v-for="(item, i) in goalsFormData"
      v-model="item.planGoal"
      v-if="!isFaithFundPage"
      :goalName="item.goalName"
      :planGoal="item.planGoal"
      :lastFYGoals="item.lastFYGoals"
      :isGoalLocked="areGoalsLocked"
      :shortCode="item.shortCode"
      :goalInfo="item.goalInfo"
      @input-validate-status="inputValidateStatusHandler"
      @goal-input-change="goalInputChangeHandler"
      :key="i"
    >
    </goal-item>
    <div v-if="isFaithFundPage" class="barnabas-fund">
      <div class="heading-bordered">
        BARNABAS FUND
      </div>
      <goal-item
        v-for="(item, i) in goalsFormData"
        v-model="item.planGoal"
        v-if="isBarnabasFundGoal(item.shortCode)"
        :goalName="item.goalName"
        :planGoal="item.planGoal"
        :lastFYGoals="item.lastFYGoals"
        :isGoalLocked="areGoalsLocked"
        :shortCode="item.shortCode"
        :goalInfo="item.goalInfo"
        @input-validate-status="inputValidateStatusHandler"
        @goal-input-change="goalInputChangeHandler"
        :key="i"
      />
    </div>
    <div v-if="isMonthlyAllocationAllowed()">
      <div class="heading-bordered">
        Monthly Allocation
      </div>

      <ul class="radio-wrap">
        <li>
          <input
            :disabled="areGoalsLocked"
            type="radio"
            id="f-option"
            v-model="radioValue"
            value="equal"
            name="selector"
          />
          <label for="f-option">
            <strong>Spread evenly across all months</strong>
            ( 1/12 of the total each month)
          </label>
          <div @click="radioInputHandler('equal')" class="check " :class="{ 'check-disabled': areGoalsLocked }"></div>
        </li>
        <li v-if="isMonthlyAllocationCustomized">
          <div>
            <input
              :disabled="areGoalsLocked"
              type="radio"
              id="s-option"
              v-model="radioValue"
              value="customize"
              name="selector"
            />
            <label for="s-option">
              <strong
                >Customize Monthly Amounts
                <a @click.prevent="viewModalHandler" v-if="radioValue === 'customize'" class="side-btn"
                  >(View Amounts)</a
                ></strong
              >
              (Plan goals month by month)
            </label>

            <div @click="radioInputHandler('customize')" class="check " :class="{ 'check-disabled': areGoalsLocked }">
              <div class="inside"></div>
            </div>
          </div>
        </li>
        <li v-else @click="modalHandler">
          <input
            type="radio"
            id="s-option"
            :disabled="areGoalsLocked"
            v-model="radioValue"
            value="customize"
            name="selector"
          />
          <label for="s-option">
            <strong>Customize Monthly Amounts</strong>
            (Plan goals month by month)
          </label>
          <div @click="radioInputHandler('customize')" class="check " :class="{ 'check-disabled': areGoalsLocked }">
            <div class="inside"></div>
          </div>
        </li>
      </ul>
      <customize-monthly-allocation
        v-if="modalShow"
        :firstTimeCustomizedMA="isFirstTimeCustomizedMA"
        :isModalShow="modalShow"
        :monthlyAllocationTotalData="customizedMATotalData"
        :areGoalsLocked="areGoalsLocked"
        :hasEqualMAValidationErrorAfterSaved="hasEqualMAValidationErrorAfterSaved"
        :column2GoalShortCode="modalColumn2GoalShortCode"
        :column1GoalShortCode="modalColumn1GoalShortCode"
        @column2-total-changed="customizedColumn2TotalChangeHandler"
        @column1-total-changed="customizedColumn1TotalChangeHandler"
        @close-modal="modalCloseHandler"
      />
    </div>
    <div class="h-footer">
      <button v-if="!areGoalsLocked" type="button" @click="saveAndNavigateHandler" class="btn btn-primary">
        <img src="@/assets/svgs/goal-planner/ic_save.svg" alt="save" />Save and Continue
      </button>
      <button v-else type="button" @click="navigateToNextPage" class="btn btn-primary">
        Continue
      </button>
      <button v-if="!areGoalsLocked" type="button" @click="navigateToNextPage" class="btn btn-primary btn--blue">
        <img src="@/assets/svgs/goal-planner/ic_skip.svg" alt="skip" />Skip
      </button>
      <button type="button" @click="navigateToDashboard()" class="btn btn-primary">
        <img src="@/assets/svgs/goal-planner/ic_return.svg" alt="return" />Return to Dashboard
        {{ areGoalsLocked ? '' : 'w/out Saving' }}
      </button>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import GoalItem from './GoalItem.vue'
import CustomizeMonthlyAllocation from '@/components/camp-goal-planner/CustomizeMonthlyAllocation.vue'
import { NextCampGoalRoute } from '@/services/utils/NextCampGoalRoute.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { GoalsConstants } from '@/constants/GoalsConstants.js'
import { mapActions, mapGetters } from 'vuex'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import CampGoalService from '@/services/goals/CampGoal.js'

export default {
  name: 'camp-goal-entry-section',
  props: {
    goalDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      radioValue: null,
      goalsFormData: [],
      isCustomizedMonthlyMode: null,
      hasEqualMAValidationErrorAfterSaved: false,
      column2TotalForCustomizeMAValidation: null,
      column1TotalForCustomizeMAValidation: null,
      modalColumn1GoalShortCode: null,
      modalColumn2GoalShortCode: null,
      customizedMATotalData: {
        column2Total: null,
        column1Total: null,
        column1FY1GoalTotal: null,
        column2FY1GoalTotal: null
      },
      column2PlanningGoalEqualMATotal: null,
      column1PlanningGoalEqualMATotal: null,
      isCustomizedMonthlyAllocationFormValidationSuccessful: null,
      hasInputValidationError: null,
      allGoalFormInputsStatus: new Map()
    }
  },
  computed: {
    ...mapGetters({
      getCustomizeMonthlyAllocationData: 'campGoalPlanner/getCustomizeMonthlyAllocationData',
      officerToolbarSelected: 'user/officerToolbarSelected',
      userLanguageKey: 'user/userLanguageKey'
    }),
    userCampKey() {
      return this.officerToolbarSelected.camp
    },
    userCampName() {
      return this.officerToolbarSelected.camp_name
    },
    isMembershipPage() {
      return this.$route.params.campGoalName === PermittedGoalRouteNames.Membership
    },
    isFaithFundPage() {
      return this.$route.params.campGoalName === PermittedGoalRouteNames.FaithFund
    },
    isChurchMinistryPage() {
      return this.$route.params.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation
    },
    isFormValidationForFirstTimeCustomizedMASuccessful() {
      let hasColumn2PlanningGoalValidationError = false
      let hasColumn1PlanningGoalValidationError = false
      this.goalsFormData.forEach(goal => {
        if (this.canCustomizeMonthlyAllocation(goal.shortCode)) {
          if (this.isChurchPresentationGoal(goal.shortCode) || this.isFaithFundGoal(goal.shortCode)) {
            hasColumn1PlanningGoalValidationError =
              parseInt(goal.planGoal) === parseInt(this.column1PlanningGoalEqualMATotal)
          } else {
            hasColumn2PlanningGoalValidationError =
              parseInt(goal.planGoal) === parseInt(this.column2PlanningGoalEqualMATotal)
          }
        }
      })
      if (this.isChurchMinistryPage || this.isFaithFundPage) {
        return (
          hasColumn2PlanningGoalValidationError &&
          hasColumn1PlanningGoalValidationError &&
          this.isCustomizedMonthlyAllocationFormValidationSuccessful
        )
      }
      return hasColumn2PlanningGoalValidationError && this.isCustomizedMonthlyAllocationFormValidationSuccessful
    },
    hasFormValidationErrorForAlreadyCustomizedMA() {
      let hasColumn2PlanningGoalValidationError = false
      let hasColumn1PlanningGoalValidationError = false

      this.goalsFormData.forEach(goal => {
        if (this.canCustomizeMonthlyAllocation(goal.shortCode)) {
          if (this.isChurchPresentationGoal(goal.shortCode) || this.isFaithFundGoal(goal.shortCode)) {
            hasColumn1PlanningGoalValidationError = !(
              parseInt(goal.planGoal) === parseInt(this.column1TotalForCustomizeMAValidation)
            )
          } else {
            hasColumn2PlanningGoalValidationError = !(
              parseInt(goal.planGoal) === parseInt(this.column2TotalForCustomizeMAValidation)
            )
          }
        }
      })
      if (this.isChurchMinistryPage || this.isFaithFundPage) {
        return hasColumn2PlanningGoalValidationError || hasColumn1PlanningGoalValidationError
      }
      return hasColumn2PlanningGoalValidationError
    },
    isMonthlyAllocationCustomized() {
      return this.goalDetails.hasOwnProperty('isMACustomized') && this.goalDetails.isMACustomized
    },
    isFirstTimeCustomizedMA() {
      return this.goalDetails.hasOwnProperty('isMACustomized') && !this.goalDetails.isMACustomized
    },
    fY1Year() {
      return (
        this.goalDetails.hasOwnProperty('fY1Goal') &&
        this.goalDetails.fY1Goal.hasOwnProperty('year') &&
        this.goalDetails.fY1Goal.year
      )
    },
    fY1Goal() {
      let fy1Goal = 0
      if (this.goalDetails.hasOwnProperty('fY1Goal') && this.goalDetails.fY1Goal.hasOwnProperty('goal')) {
        fy1Goal = !this.isMembershipPage
          ? CampGoalService.prependDollarSign(this.goalDetails.fY1Goal.goal)
          : this.goalDetails.fY1Goal.goal
      }

      return fy1Goal
    },
    areGoalsLocked() {
      return this.goalDetails.areGoalsLocked
    }
  },
  async beforeUpdate() {
    this.goalsFormData = this.goalDetails.goals
    this.setMonthlyAllocationPropsData()
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setCustomizeMonthlyAllocationData: 'campGoalPlanner/setCustomizeMonthlyAllocationData',
      setCampGoalsProgressStatus: 'campGoalPlanner/setCampGoalsProgressStatus',
      // Send goals data POST api
      sendChurchMinistryAndPlacementsGoalsData: 'campGoalPlanner/sendChurchMinistryAndPlacementsGoalsData',
      sendGideonCardBibleGoalsData: 'campGoalPlanner/sendGideonCardBibleGoalsData',
      sendFaithFundGoalsData: 'campGoalPlanner/sendFaithFundGoalsData',
      sendAuxiliaryScriptureGoalsData: 'campGoalPlanner/sendAuxiliaryScriptureGoalsData',
      sendCampDesignatedGoalsData: 'campGoalPlanner/sendCampDesignatedGoalsData',
      sendMembershipGoalsData: 'campGoalPlanner/sendMembershipGoalsData'
    }),
    isFaithFundGoal(shortCode) {
      return shortCode === GoalShortCodes.FaithFund
    },
    isBarnabasFundGoal(shortCode) {
      return shortCode === GoalShortCodes.BarnabasFund
    },
    isChurchPresentationGoal(shortCode) {
      return shortCode === GoalShortCodes.ChurchPresentation
    },
    isMonthlyAllocationAllowed() {
      return (
        this.$route.params.campGoalName !== PermittedGoalRouteNames.CampDesignated &&
        this.$route.params.campGoalName !== PermittedGoalRouteNames.Membership
      )
    },
    canCustomizeMonthlyAllocation(shortCode) {
      return (
        shortCode === GoalShortCodes.ChurchMinistry ||
        shortCode === GoalShortCodes.GideonCard ||
        shortCode === GoalShortCodes.AuxiliaryScripture ||
        shortCode === GoalShortCodes.FaithFund ||
        shortCode === GoalShortCodes.BarnabasFund ||
        shortCode === GoalShortCodes.ChurchPresentation
      )
    },
    radioInputHandler(value) {
      if (this.areGoalsLocked) return
      if (value === GoalsConstants.SpreadEqually || value === GoalsConstants.Customize) {
        this.radioValue = value
      }
    },
    customizedColumn2TotalChangeHandler(column2Total) {
      this.goalsFormData.forEach(itm => {
        if (
          this.canCustomizeMonthlyAllocation(itm.shortCode) &&
          !this.isFaithFundGoal(itm.shortCode) &&
          !this.isChurchPresentationGoal(itm.shortCode)
        ) {
          itm.planGoal = parseInt(column2Total)
        }
      })
    },
    customizedColumn1TotalChangeHandler(column1Total) {
      this.goalsFormData.forEach(itm => {
        if (this.isChurchPresentationGoal(itm.shortCode) || this.isFaithFundGoal(itm.shortCode)) {
          itm.planGoal = parseInt(column1Total)
        }
      })
    },
    viewModalHandler() {
      this.modalShow = true
    },
    modalHandler() {
      if (!this.areGoalsLocked) {
        this.modalShow = true
      }
    },
    async modalCloseHandler(value = '', customizedMATotalGoals = null) {
      this.isCustomizedMonthlyAllocationFormValidationSuccessful = false
      this.modalShow = false
      this.hasEqualMAValidationErrorAfterSaved = false
      if (value === 'saved') {
        this.radioValue = GoalsConstants.Customize
        this.isCustomizedMonthlyAllocationFormValidationSuccessful = true
        if (customizedMATotalGoals !== null) {
          this.column2PlanningGoalEqualMATotal = customizedMATotalGoals.column2PlanningGoalsTotal
          this.column1PlanningGoalEqualMATotal = customizedMATotalGoals.column1PlanningGoalsTotal
          this.column2TotalForCustomizeMAValidation = customizedMATotalGoals.column2PlanningGoalsTotal
          this.column1TotalForCustomizeMAValidation = customizedMATotalGoals.column1PlanningGoalsTotal
        }
      } else {
        this.radioValue = this.isMonthlyAllocationCustomized ? GoalsConstants.Customize : GoalsConstants.SpreadEqually
      }
    },
    navigateToDashboard() {
      this.$router.push({ name: 'goal-planner-cm', params: { campGoalName: 'dashboard' } })
    },
    getCorrectFormDataPayload() {
      const payload = { CampOrgKey: this.userCampKey, IsCustomizedMonthlyMode: this.isCustomizedMonthlyMode }
      const payloadWithoutMonthlyAllocation = { CampOrgKey: this.userCampKey }
      const hasMonthlyAllocation = this.isMonthlyAllocationAllowed()
      this.goalsFormData.forEach(goal => {
        switch (goal.shortCode) {
          case GoalShortCodes.ChurchMinistry:
            payload.ChurchMinistryGoal = parseInt(goal.planGoal)
            break
          case GoalShortCodes.ChurchPresentation:
            payload.ChurchPresentationGoal = parseInt(goal.planGoal)
            break
          case GoalShortCodes.GideonCard:
            payload.GideonsCardReceiptGoal = parseInt(goal.planGoal)
            break
          case GoalShortCodes.GideonCardPresentation:
            payload.GideonCardPresentationGoal = parseInt(goal.planGoal)
            break
          case GoalShortCodes.GideonCardCollection:
            payload.GideonCardCollectionGoal = parseInt(goal.planGoal)
            break
          case GoalShortCodes.AuxiliaryScripture:
            payload.TotalAuxiliaryScripture = parseInt(goal.planGoal)
            break
          case GoalShortCodes.CampDesignated:
            payloadWithoutMonthlyAllocation.TotalCampDesignated = parseInt(goal.planGoal)
            break
          case GoalShortCodes.FaithFund:
            payload.FaithFund = parseInt(goal.planGoal)
            break
          case GoalShortCodes.BarnabasFund:
            payload.BarnabasFund = parseInt(goal.planGoal)
            break
          case GoalShortCodes.GideonMembership:
            payloadWithoutMonthlyAllocation.GideonMembership = parseInt(goal.planGoal)
            break
          case GoalShortCodes.AuxiliaryMembership:
            payloadWithoutMonthlyAllocation.AuxiliaryMembership = parseInt(goal.planGoal)
            break
          default:
            break
        }
      })
      return hasMonthlyAllocation ? payload : payloadWithoutMonthlyAllocation
    },
    sendCorrespondingGoalsDataByRouteGoalName(routeGoalName) {
      return new Map([
        [PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation, this.sendChurchMinistryAndPlacementsGoalsData],
        [PermittedGoalRouteNames.GideonCard, this.sendGideonCardBibleGoalsData],
        [PermittedGoalRouteNames.FaithFund, this.sendFaithFundGoalsData],
        [PermittedGoalRouteNames.AuxiliaryScripture, this.sendAuxiliaryScriptureGoalsData],
        [PermittedGoalRouteNames.CampDesignated, this.sendCampDesignatedGoalsData],
        [PermittedGoalRouteNames.Membership, this.sendMembershipGoalsData]
      ]).get(routeGoalName)
    },
    inputValidateStatusHandler(status, shortCode) {
      let allInputsValidatedSuccessfully = true
      if (status === 'ERROR') {
        this.allGoalFormInputsStatus.set(shortCode, false)
      }
      if (status === 'SUCCESS') {
        this.allGoalFormInputsStatus.set(shortCode, true)
      }
      this.allGoalFormInputsStatus.forEach(value => {
        allInputsValidatedSuccessfully = allInputsValidatedSuccessfully && value
      })
      this.hasInputValidationError = !allInputsValidatedSuccessfully
    },
    async saveAndNavigateHandler() {
      const sendAndUpdateCorrespondingGoals = async () => {
        this.modalShow = false
        const response = await this.sendCorrespondingGoalsDataByRouteGoalName(this.$route.params.campGoalName)(
          this.getCorrectFormDataPayload()
        )
        if (response === 'Success') {
          // calling stepper GET api after successful goal update
          await this.setCampGoalsProgressStatus({ campKey: this.userCampKey })
          this.navigateToNextPage()
        }
      }
      if (this.areGoalsLocked) {
        this.navigateToNextPage()
        return
      }
      if (this.hasInputValidationError !== null && this.hasInputValidationError) {
        return
      }

      if (
        this.$route.params.campGoalName === PermittedGoalRouteNames.CampDesignated ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.Membership
      ) {
        await sendAndUpdateCorrespondingGoals()
      } else if (
        this.$route.params.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.GideonCard ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.AuxiliaryScripture ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.FaithFund
      ) {
        if (this.isFirstTimeCustomizedMA) {
          if (
            this.radioValue === GoalsConstants.SpreadEqually ||
            this.isFormValidationForFirstTimeCustomizedMASuccessful
          ) {
            await sendAndUpdateCorrespondingGoals()
          } else {
            this.hasEqualMAValidationErrorAfterSaved = true
            this.modalShow = true
          }
        } else {
          if (this.radioValue === GoalsConstants.SpreadEqually || !this.hasFormValidationErrorForAlreadyCustomizedMA) {
            await sendAndUpdateCorrespondingGoals()
          } else if (this.hasFormValidationErrorForAlreadyCustomizedMA) {
            // showing modal with validation error
            this.modalShow = true
          }
        }
      } else {
        this.navigateToNextPage()
      }
    },
    navigateToNextPage() {
      const currentRoute = this.$route.params.campGoalName
      if (currentRoute === NextCampGoalRoute(currentRoute)) return
      this.$router.push({
        name: 'goal-planner-cm',
        params: { campGoalName: NextCampGoalRoute(currentRoute) }
      })
    },
    setMonthlyAllocationPropsData() {
      this.goalsFormData &&
        this.goalsFormData.forEach(goal => {
          if (this.canCustomizeMonthlyAllocation(goal.shortCode)) {
            if (this.isFaithFundGoal(goal.shortCode)) {
              this.customizedMATotalData.column1Total = goal.planGoal
              this.customizedMATotalData.column1FY1GoalTotal = goal.lastFYGoals.fY1Goal.goal
              this.modalColumn1GoalShortCode = goal.shortCode
            } else if (this.isChurchPresentationGoal(goal.shortCode)) {
              this.customizedMATotalData.column1Total = goal.planGoal
              this.modalColumn1GoalShortCode = goal.shortCode
            } else {
              this.customizedMATotalData.column2Total = goal.planGoal
              this.customizedMATotalData.column2FY1GoalTotal = goal.lastFYGoals.fY1Goal.goal
              this.modalColumn2GoalShortCode = goal.shortCode
            }
          }
        })
    },
    goalInputChangeHandler(value, shortCode) {
      if (this.canCustomizeMonthlyAllocation(shortCode)) {
        if (this.isFaithFundGoal(shortCode)) {
          this.customizedMATotalData.column1Total = value
        } else if (this.isChurchPresentationGoal(shortCode)) {
          this.customizedMATotalData.column1Total = value
        } else {
          this.customizedMATotalData.column2Total = value
        }
      }
    }
  },
  watch: {
    isMonthlyAllocationCustomized: {
      handler(newVal, oldVal) {
        this.radioValue = newVal ? GoalsConstants.Customize : GoalsConstants.SpreadEqually
      },
      deep: true
    },
    radioValue(newVal) {
      this.isCustomizedMonthlyMode = newVal === GoalsConstants.Customize
    },
    async userCampName() {
      // need to call customize monthly allocation API for validation purpose every time user select different camp
      if (
        this.$route.params.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.GideonCard ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.AuxiliaryScripture ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.FaithFund
      ) {
        await this.setCustomizeMonthlyAllocationData({
          campKey: this.userCampKey,
          langKey: this.userLanguageKey,
          routeCampGoal: this.$route.params.campGoalName
        })
      }
    }
  },
  async created() {
    // setting form data
    this.goalsFormData = this.goalDetails.goals
    this.radioValue = this.isMonthlyAllocationCustomized ? GoalsConstants.Customize : GoalsConstants.SpreadEqually
    this.isCustomizedMonthlyMode = this.isMonthlyAllocationCustomized
    this.setMonthlyAllocationPropsData()
    // need to call customize monthly allocation API for validation purpose
    if (
      this.$route.params.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation ||
      this.$route.params.campGoalName === PermittedGoalRouteNames.GideonCard ||
      this.$route.params.campGoalName === PermittedGoalRouteNames.AuxiliaryScripture ||
      this.$route.params.campGoalName === PermittedGoalRouteNames.FaithFund
    ) {
      await this.setCustomizeMonthlyAllocationData({
        campKey: this.userCampKey,
        langKey: this.userLanguageKey,
        routeCampGoal: this.$route.params.campGoalName
      })
    }
    // setting monthly allocation total validation values for first time
    this.column2TotalForCustomizeMAValidation = window.structuredClone(
      this.getCustomizeMonthlyAllocationData
    ).column2PlanningGoalsTotal
    this.column1TotalForCustomizeMAValidation = window.structuredClone(
      this.getCustomizeMonthlyAllocationData
    ).column1PlanningGoalsTotal
  },
  components: {
    GoalItem,
    CustomizeMonthlyAllocation
  }
}
</script>
<style lang="scss">
.check-disabled {
  cursor: not-allowed !important;
}
.side-btn {
  color: #0097b9;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #0097b9;
  }
}
.h-footer {
  background: #e0e8ef;
  display: flex;
  justify-content: flex-end;
  padding: 18px 38px;
  margin: 0 -38px;
  .btn {
    margin-left: 15px;
    text-transform: none;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    &.btn--blue {
      background: #0097b9;
      &:hover {
        background: #003946;
      }
    }
    img {
      margin-right: 8px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.heading-bordered {
  border-bottom: 1px solid #ccc;
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 45px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 6px;
  margin-top: 28px;
  margin-bottom: 15px;
  span {
    font-size: 30px;
    letter-spacing: 0px;
    line-height: 36px;
  }
}

.card .h-content {
  margin: 20px 0;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
}
.radio-wrap {
  display: flex;
  margin-bottom: 45px;
  input[type='radio']:disabled ~ label {
    cursor: not-allowed;
  }
}
ul.radio-wrap li {
  list-style: none;
  position: relative;
  margin-right: 40px;
}
ul.radio-wrap li .check {
  display: block;
  position: absolute;
  border: 1px solid #003946;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  top: 7px;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  cursor: pointer;
}
ul.radio-wrap li input[type='radio'] {
  position: absolute;
  visibility: hidden;
}
ul.radio-wrap li:hover .check {
  border: 1px solid #003946;
}
ul.radio-wrap li label {
  padding-left: 38px;
  display: flex;
  flex-direction: column;
  color: #212121;
}
ul.radio-wrap li .check::before {
  display: block;
  position: relative;
  content: '';
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 2px;
  left: 0px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type='radio']:checked ~ .check {
  border: 1px solid #003946;
}

input[type='radio']:checked ~ .check::before {
  background: #003946;
}

input[type='radio']:checked ~ label {
  color: #003946;
}
.h-row__data {
  margin-left: 40px;
  img {
    margin-right: 5px;
    cursor: pointer;
  }
}
.h-footer {
  background: #e0e8ef;
  display: flex;
  justify-content: flex-end;
  padding: 18px 38px;
  margin: 0 -38px;
  .btn {
    margin-left: 15px;
    text-transform: none;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    &.btn--blue {
      background: #0097b9;
      &:hover {
        background: #003946;
      }
    }
    img {
      margin-right: 8px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.custom-info {
  width: max-content;
  padding: 0.6em 1em;
  margin: 1em 0 0;
}
.barnabas-fund {
  padding-left: 35px;
}
.faith-fund-label {
  margin-left: 35px;
}
.faith-fund-label label,
.barnabas-fund label {
  justify-content: flex-end;
  padding-right: 40px;
}
@media (min-width: 320px) and (max-width: 900px) {
  .h-row {
    flex-direction: column;
    align-items: flex-start;
    label {
      margin-bottom: 4px;
    }
    input[type='text'] {
      margin-bottom: 6px;
    }
    .h-row__data {
      margin: 10px 0 0;
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .radio-wrap {
    flex-direction: column;
    li {
      margin: 0 0 10px;
    }
  }
  .heading-bordered {
    font-size: 28px;
    line-height: 30px;
    span {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .main-heading {
    font-size: 28px;
    line-height: 30px;
  }
  .g-panel {
    flex-direction: column;
    .g-panel__rgt {
      margin: 10px 0 0;
    }
  }
}
@media (min-width: 300px) and (max-width: 650px) {
  .h-footer {
    flex-direction: column;
    margin: 0 -25px;
    padding: 15px 25px;
    .btn {
      margin-left: 0;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .card {
    padding: 30px 25px 0;
  }
}
@media (min-width: 300px) and (max-width: 576px) {
  .widget {
    .widget__row {
      flex-wrap: wrap;
      .row__column input[type='text'] {
        max-width: initial;
        min-height: 38px;
        margin: 5px 0;
        width: 100%;
      }
    }
    .widget__footer-row {
      flex-wrap: wrap;
      justify-content: space-between;
      .footer-row__heading {
        min-width: auto;
      }
      .footer-row__total-col {
        width: auto;
      }
    }
  }
}
@media (min-width: 961px) and (max-width: 1030px) {
  .widget {
    .widget__row {
      padding: 7px 9px;
      .row__column {
        input[type='text'] {
          max-width: 112px;
        }
        .btn {
          padding: 10px;
        }
      }
    }
  }
}
</style>
