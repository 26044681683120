<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="page-navigation">
    <div :class="getIconWrapperStyles(i)" v-for="(step, i) in stepper" :key="generateUUID()">
      <router-link :to="{ name: 'goal-planner-cm', params: { campGoalName: step.routePath } }">
        <div class="nav-item nav-item-1 " :class="getNavItemStyles(i)">
          <div class="icon">
            <img :src="step.imgPath" :alt="step.alt" />
          </div>
          <div class="text">{{ step.name }}</div>
        </div>
      </router-link>
      <div :class="getStepperStyles(i)"></div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import { mapActions, mapGetters } from 'vuex'
import { StepperOrderNumber } from '@/constants/StepperOrderNumber.js'
export default {
  data() {
    return {
      currentActivePosition: -1,
      stepper: [
        {
          name: ' Church Ministry / Presentation',
          alt: 'Church Ministry/Presentation',
          routePath: PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation,
          imgPath: require('@/assets/pngs/goal-planner/ic_church.png')
        },
        {
          name: 'GideonCard',
          alt: 'GideonCard',
          routePath: PermittedGoalRouteNames.GideonCard,
          imgPath: require('@/assets/pngs/goal-planner/ic_gideoncard.png')
        },
        {
          name: 'Faith Fund',
          alt: 'Faith Fund ',
          routePath: PermittedGoalRouteNames.FaithFund,
          imgPath: require('@/assets/pngs/goal-planner/ic_faith.png')
        },
        {
          name: 'Auxiliary Scripture',
          alt: 'Auxiliary Scripture',
          routePath: PermittedGoalRouteNames.AuxiliaryScripture,
          imgPath: require('@/assets/pngs/goal-planner/ic_auxiliary.png')
        },
        {
          name: 'Camp Designated ',
          alt: 'Camp Designated',
          routePath: PermittedGoalRouteNames.CampDesignated,
          imgPath: require('@/assets/pngs/goal-planner/ic_camp_designated.png')
        },
        {
          name: 'Placements',
          alt: 'Placements',
          routePath: PermittedGoalRouteNames.Placements,
          imgPath: require('@/assets/pngs/goal-planner/ic_placements.png')
        },
        {
          name: 'Personal Workers Testaments',
          alt: 'Personal Workers Testaments',
          routePath: PermittedGoalRouteNames.PersonalWorkersTestaments,
          imgPath: require('@/assets/pngs/goal-planner/ic_personal.png')
        },
        {
          name: 'Membership',
          alt: 'Membership',
          routePath: PermittedGoalRouteNames.Membership,
          imgPath: require('@/assets/pngs/goal-planner/ic_membership.png')
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      campGoalsProgressStatus: 'campGoalPlanner/campGoalsProgressStatus',
      officerToolbarSelected: 'user/officerToolbarSelected'
    }),
    userCampKey() {
      return this.officerToolbarSelected.camp
    },
    userCampName() {
      return this.officerToolbarSelected.camp_name
    },
    currentRoutePath() {
      return this.$route.path
    }
  },
  methods: {
    ...mapActions({
      setCampGoalsProgressStatus: 'campGoalPlanner/setCampGoalsProgressStatus'
    }),
    getIconWrapperStyles(index) {
      return index === this.stepper.length - 1 ? 'last-step-item' : 'step-item'
    },
    getNavItemStyles(index) {
      if (this.campGoalsProgressStatus.includes(index) && this.currentActivePosition === index) {
        return 'active'
      }
      return this.campGoalsProgressStatus.includes(index)
        ? 'completed'
        : this.currentActivePosition === index
        ? 'active'
        : ''
    },
    getStepperStyles(index) {
      return [
        index < this.stepper.length - 1 && 'sep',
        this.campGoalsProgressStatus.includes(index + 1)
          ? 'completed'
          : this.currentActivePosition === index + 1
          ? 'active'
          : ''
      ]
    },
    generateUUID() {
      return crypto.randomUUID()
    },
    getCurrentActiveStep(ROUTE_GOAL_NAME) {
      return new Map([
        [PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation, StepperOrderNumber.ChurchMinistry],
        [PermittedGoalRouteNames.GideonCard, StepperOrderNumber.GideonCard],
        [PermittedGoalRouteNames.FaithFund, StepperOrderNumber.FaithFund],
        [PermittedGoalRouteNames.AuxiliaryScripture, StepperOrderNumber.AuxiliaryScripture],
        [PermittedGoalRouteNames.CampDesignated, StepperOrderNumber.CampDesignated],
        [PermittedGoalRouteNames.Placements, StepperOrderNumber.Placements],
        [PermittedGoalRouteNames.PersonalWorkersTestaments, StepperOrderNumber.PersonalWorkersTestaments],
        [PermittedGoalRouteNames.Membership, StepperOrderNumber.Membership]
      ]).get(ROUTE_GOAL_NAME)
    }
  },
  async created() {
    this.currentActivePosition = this.getCurrentActiveStep(this.$route.params.campGoalName)
    await this.setCampGoalsProgressStatus({ campKey: this.userCampKey })
  },
  watch: {
    userCampName() {
      this.setCampGoalsProgressStatus({ campKey: this.userCampKey })
    },
    currentRoutePath: {
      handler() {
        this.currentActivePosition = this.getCurrentActiveStep(this.$route.params.campGoalName)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.last-step-item {
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: auto;
}
.step-item {
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.page-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 5px 50px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      img {
        width: 60px;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
      }
      .text {
        color: #000;
      }
    }

    &.completed {
      .icon {
        background-color: green;
        svg {
          fill: #f7f4f4 !important;
        }
      }

      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    &.completed {
      background-color: green;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 65px;
    width: 65px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 117px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #000;
    font-family: $open-sans;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 20px;
    text-align: center;
    word-break: break-word;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
@media (min-width: 961px) and (max-width: 1250px) {
  .page-navigation {
    .text {
      widows: 100px;
      font-size: 13px;
    }
  }
}
@media (min-width: 300px) and (max-width: 960px) {
  .step-item {
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
  .page-navigation {
    .sep {
      left: 33px;
    }
    .text {
      word-break: unset;
    }
  }
}
</style>
