<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1>{{ pageTitle }}</h1>
    </page-header>
    <security-banner v-if="!hasAccess" :i18n="translations.components"></security-banner>
    <div v-if="hasAccess">
      <camp-selector
        @camp_changed="campChangeHandlerForStateDashboardRedirect"
        ref="campSelect"
        :showCamp="true"
        :onlyUSA="onlyUSA"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <page-body class="mx-lg">
        <GoalPlanningStepper v-if="isGoalsPage" />
        <Dashboard v-if="!isGoalsPage" />
        <CampGoalPage v-if="isGoalsPage" :pageGoalName="getCorrectGoalNameFromRoutePath" :key="$route.path" />
      </page-body>
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import Dashboard from './goal-planner/Dashboard.vue'
import CampGoalPage from './goal-planner/CampGoalPage.vue'
import GoalPlanningStepper from '@/components/camp-goal-planner/GoalPlanningStepper.vue'
import { PermittedGoalRoutes } from '@/constants/PermittedGoalRoutes.js'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
export default {
  name: 'goal-planner',
  mixins: [translationMixin, stateBreadcrumbMixin],
  props: ['campGoalName'],
  data() {
    return {
      translations: {},
      onlyUSA: true
    }
  },
  async created() {
    await this.getTranslations()
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      saveOfficerToolbarCampName: 'user/saveOfficerToolbarCampName',
      resetStateToCampDashboardRedirectionData: 'stateGoalPlanner/resetStateToCampDashboardRedirectionData'
    }),
    campChangeHandlerForStateDashboardRedirect() {
      if (this.stateToCampDashboardRedirectionData.isRedirectedBefore) {
        this.saveOfficerToolbarCampName(this.stateToCampDashboardRedirectionData.campName)
        // setting selected camp name when redirected from state dashboard
        this.$refs.campSelect.selectedCampName = this.stateToCampDashboardRedirectionData.campName
        this.resetStateToCampDashboardRedirectionData()
      }
    },
    isSubRoutesOfCampGoalPlanner(path) {
      return PermittedGoalRoutes.filter(route => route === path).length > 0
    },
    async getTranslations() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('security-banner', 'camp-select')
      ]).then(results => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.setLoadingStatus(false)
      })
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userLogin: 'user/userLogin',
      userStateKey: 'user/userStateKey',
      individualProfileHeader: 'membership/individualProfileHeader',
      stateToCampDashboardRedirectionData: 'stateGoalPlanner/stateToCampDashboardRedirectionData'
    }),
    getCorrectGoalNameFromRoutePath() {
      if (this.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation) return 'church ministry'
      return this.campGoalName && this.campGoalName.split('-').join(' ')
    },
    isGoalsPage() {
      return this.campGoalName && this.campGoalName !== 'dashboard' && this.campGoalName !== 'menu'
    },
    hasAccess() {
      let hq = constantData.super_roles.find(csr => csr.name === 'Headquarters')
      // checking if the login user has `/officers/camp/goal-planner/cm/menu` in his menu list
      // if exists the user is granted access to other  sub-routes  of camp-goal-planner
      if (
        (this.isSubRoutesOfCampGoalPlanner(this.$route.path) && this.isInMenu('/officers/camp/goal-planner/cm/menu')) ||
        (this.isSubRoutesOfCampGoalPlanner(this.$route.path) &&
          !!this.userLogin.croles &&
          this.userLogin.croles.filter(cf => cf.rle_key === hq).length > 0)
      )
        return true

      return (
        this.isInMenu(this.$route.path) ||
        (!!this.userLogin.croles && this.userLogin.croles.filter(cf => cf.rle_key === hq).length > 0)
      )
    },
    pageTitle() {
      return {
        cm: this.translations.tcCampGoalPlannerTitle
      }[this.$route.path.split('/')[4]]
    }
  },

  components: {
    campSelector: CampSelect,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
    Dashboard,
    CampGoalPage,
    GoalPlanningStepper
  }
}
</script>
