export const PermittedGoalRoutes = [
  '/officers/camp/goal-planner/cm',
  '/officers/camp/goal-planner/cm/menu',
  '/officers/camp/goal-planner/cm/dashboard',
  '/officers/camp/goal-planner/cm/church-ministry-and-church-presentations',
  '/officers/camp/goal-planner/cm/gideon-card',
  '/officers/camp/goal-planner/cm/faith-fund',
  '/officers/camp/goal-planner/cm/auxiliary-scripture',
  '/officers/camp/goal-planner/cm/camp-designated',
  '/officers/camp/goal-planner/cm/barnabas-fund',
  '/officers/camp/goal-planner/cm/placements',
  '/officers/camp/goal-planner/cm/personal-workers-testaments',
  '/officers/camp/goal-planner/cm/membership'
]
