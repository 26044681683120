import { GoalShortCodes } from '@/constants/GoalShortCodes.js'

const tooltipMessagesByShortCode = new Map([
  [
    GoalShortCodes.ChurchPresentation,
    'How many churches does your camp have the potential to share the Gideon ministry with this year?'
  ],
  [
    GoalShortCodes.Bible,
    `How many hotel rooms, doctor's offices, etc. does your camp have the potential to place a copy of God’s Word in this year?`
  ],
  [
    GoalShortCodes.Hospital,
    `How many hospital rooms or nursing homes does your camp have the potential to place a copy of God’s Word in this year? `
  ],
  [GoalShortCodes.College, `How many college students’ hands does your camp want to place a Testament in this year?`],
  [
    GoalShortCodes.Youth,
    `How many students does your camp have the potential to reach with a red Testament this year? `
  ],
  [
    GoalShortCodes.SidewalkYouth,
    `How many students does your camp want to hand an orange Testament to this year at fairs, VBS, or on sidewalks?  `
  ],
  [GoalShortCodes.Service, `How many police, fire personnel, and EMTs can your camp hand a Testament to this year? `],
  [
    GoalShortCodes.GideonFacilities,
    `How many prisoners, rescue mission residents, and food distribution recipients does your camp want to reach with the Gospel this year?`
  ],
  [
    GoalShortCodes.Medical,
    `How many medical, administrative, and support personnel in medical facilities does your camp have the potential to reach with a copy of God’s Word this year?`
  ],
  [
    GoalShortCodes.AuxiliaryFacilities,
    `How many female inmates, domestic violence shelter residents, and staff/patients of crisis pregnancy centers will your camp reach this year? `
  ],
  [
    GoalShortCodes.AuxiliaryFacilities9,
    `How many female inmates, domestic violence shelter residents, and staff/patients of crisis pregnancy centers will your camp reach this year? `
  ],
  [GoalShortCodes.GideonMembership, `How many new Gideon members will join your camp this year?`],
  [GoalShortCodes.AuxiliaryMembership, `How many new Auxiliary members will join your camp this year?`],
  [
    GoalShortCodes.FaithFund,
    `Consider these opportunities as you plan for your Faith Fund goal:
Annual Pastors Event, Faith Fund Rallies, Countdown 100, Auxiliary HEART Program, Auxiliary Events
`
  ],
  [
    GoalShortCodes.CampDesignated,
    `Your camp may establish an optional Camp Designated goal to help cover the cost of Scriptures placed or distributed in your camp area.`
  ]
])
export const ToolTipsMessage = shortCode => {
  return tooltipMessagesByShortCode.get(shortCode)
}
