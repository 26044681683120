import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
export const GoalsWithTooltips = [
  GoalShortCodes.ChurchPresentation,
  GoalShortCodes.Bible,
  GoalShortCodes.Hospital,
  GoalShortCodes.College,
  GoalShortCodes.Youth,
  GoalShortCodes.SidewalkYouth,
  GoalShortCodes.Service,
  GoalShortCodes.GideonFacilities,
  GoalShortCodes.Medical,
  GoalShortCodes.AuxiliaryFacilities,
  GoalShortCodes.AuxiliaryFacilities9,
  GoalShortCodes.FaithFund,
  GoalShortCodes.GideonMembership,
  GoalShortCodes.AuxiliaryMembership,
  GoalShortCodes.CampDesignated
]
