var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrorDashboard && !_vm.loadingStatus)?_c('b-alert',{staticStyle:{"margin-top":"80px"},attrs:{"show":"","variant":"danger"}},[_c('b-icon',{staticClass:"warning-icon",attrs:{"icon":"exclamation-circle"}}),_vm._v(" There is no goals data in the database for the camp you are looking for. Please try selecting another camp.")],1):_vm._e(),(_vm.hasErrorDashboard !== null && !_vm.hasErrorDashboard)?_c('camp-goal-layout',{attrs:{"goalName":"dashboard"},scopedSlots:_vm._u([{key:"goal-lock-warn-info",fn:function(){return [_c('b-alert',{attrs:{"show":_vm.isCampGoalsLocked,"variant":"danger"}},[_vm._v(" GOALS HAVE BEEN LOCKED BY THE LOCAL CAMP. PLEASE DO NOT UNLOCK AND MAKE CHANGES WITHOUT FIRST DISCUSSING WITH THE CAMP PRESIDENT. ")])]},proxy:true},{key:"progress-badge",fn:function(){return [(_vm.isCampGoalsLocked)?_c('span',{staticClass:"camp-lock-text"},[_vm._v(" - CAMP IS LOCKED")]):_vm._e(),_c('span',{staticClass:"badge badge--success"},[_vm._v("("+_vm._s(_vm.goalsPercentComplete)+"% Completed)")])]},proxy:true},{key:"body",fn:function(){return [_vm._l((_vm.GOAL_CATEGORY_KEYS),function(GOAL_CAT_KEY,GOAL_CAT_NAME){return _c('dashboard-table',{key:GOAL_CAT_KEY,attrs:{"lastThreeFYs":_vm.getLastThreeFYs,"goalCategory":_vm.getTableGoalCategory(GOAL_CAT_KEY),"campGoalData":_vm.getDashboardTableData(GOAL_CAT_KEY)},scopedSlots:_vm._u([(GOAL_CAT_NAME === 'RECEIPTS')?{key:"header-buttons",fn:function(){return [_c('div',{staticClass:"planner-header__lft"},[_c('a',{staticClass:"btn btn-primary btn--lgt-blue",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadCampLockGoalsReport({
                  campKey: _vm.campKey,
                  fiscalYear: _vm.currentFiscalYear,
                  campName: _vm.campName
                })}}},[_c('img',{attrs:{"src":require("@/assets/svgs/goal-planner/ic_download.svg"),"alt":"Lock Goals Report"}}),_vm._v(" Camp Lock Goals Report ")]),(!_vm.isCampGoalsLocked)?_c('router-link',{staticClass:"btn btn-primary btn--yellow",attrs:{"to":{
                name: 'goal-planner-cm',
                params: { campGoalName: 'church-ministry-and-church-presentations' }
              }}},[_c('img',{attrs:{"src":require("@/assets/svgs/goal-planner/ic_play.svg"),"alt":"Begin Goal Planning"}}),_vm._v(" Begin Goal Planning")]):_vm._e()],1)]},proxy:true}:null,(GOAL_CAT_NAME === 'RECEIPTS')?{key:"table-total",fn:function(){return [_c('b-tr',[_c('b-td',[_c('strong',[_vm._v("Total")])]),_vm._l((_vm.getTotalCampGoalByCategoryKey(GOAL_CAT_KEY)),function(totalValue,fiscalYear){return _c('b-td',{key:fiscalYear,staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(totalValue))])])})],2)]},proxy:true}:null],null,true)})}),_c('div',{staticClass:"planner-header"},[_c('h4',[_vm._v("FINALIZE GOAL PLANNING "),_c('span',[_vm._v("Lock Your Goal")])])]),_c('div',{staticClass:"g-panel"},[_c('div',{staticClass:"g-panel__lft"},[_c('p',{staticClass:"lft__width"},[_vm._v(" Select the Lock Camp Goals button to finalize goal planning. Camp planning must be completed and finished by "),_c('strong',[_vm._v(_vm._s(_vm.getGoalPlanningCompletionDate)+".")])])]),(!_vm.isGoalsCompleted && !_vm.isCampGoalsLocked)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ customClass: 'custom-tooltip' }),expression:"{ customClass: 'custom-tooltip' }",modifiers:{"hover":true}}],attrs:{"tabindex":"0","title":'Camp cannot be locked as not all goals are completed.'}},[_c('button',{staticClass:"btn btn-primary btn--yellow",attrs:{"type":"button","disabled":_vm.isLockGoalsButtonDisabled},on:{"click":_vm.lockOrUnlockCampGoalsHandler}},[_vm._v(" "+_vm._s(((_vm.isCampGoalsLocked ? 'Unlock' : 'Lock') + " Your Goal"))+" ")])]):_c('button',{staticClass:"btn btn-primary btn--yellow",attrs:{"type":"button","disabled":_vm.isLockGoalsButtonDisabled},on:{"click":_vm.lockOrUnlockCampGoalsHandler}},[_vm._v(" "+_vm._s(((_vm.isCampGoalsLocked ? 'Unlock' : 'Lock') + " Your Goal"))+" ")])])]},proxy:true}],null,false,3435902691)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }